import React from "react";
import { useState } from "react";
import AppAppBar from "../AppAppBar";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import getMPTheme from "../../theme/getMPTheme";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Footer from "../Footer";
import Divider from "@mui/material/Divider";

const People_analytics = () => {
  const [mode, setMode] = useState("light");
  const [showCustomTheme, setShowCustomTheme] = useState(true);
  const MPTheme = createTheme(getMPTheme(mode));
  const defaultTheme = createTheme({ palette: { mode } });

  const pageStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage:
      "radial-gradient(ellipse 80% 59% at 50% -15%, hsl(120, 100%, 90%), transparent)",
  };

  return (
    <>
      <ThemeProvider theme={showCustomTheme ? MPTheme : defaultTheme}>
        <Box style={pageStyle}>
          <CssBaseline /> {/* Normalize styles */}
          <Box
            sx={{
              display: "flex",
              width: "100%",
              backgroundImage: `url(${"People_analytics.jpeg"})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <Container style={{ display: "flex", marginBottom: "40vh" }}>
              <AppAppBar />
            </Container>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "59%",
            }}
          >
            <div>
              <h1>People Analytics</h1>
              <h2>
                Harnessing People Analytics Through an Organisational Psychology
                Lens
              </h2>
              <p>
                In an increasingly data-driven world, people analytics has
                quickly become an essential tool for organisations to enhance
                decision-making, improve employee engagement, and drive business
                success. However, the true power of people analytics lies not
                just in the data, but in how it’s interpreted and applied. When
                combined with principles from organisational psychology, people
                analytics can offer powerful insights into the nuances of human
                behaviour within the workplace, revealing patterns and guiding
                decisions that foster a thriving work culture.
              </p>

              <h3>What is People Analytics?</h3>
              <p>
                At its core, people analytics involves collecting and analyzing
                employee data to uncover trends, predict future behavior, and
                inform business decisions. Data points can cover a vast array of
                employee information, from performance metrics and engagement
                survey results to absenteeism rates and attrition data. Through
                sophisticated analysis, these insights can drive strategies in
                hiring, onboarding, leadership development, performance
                management, and more. But the key to transformative people
                analytics lies in understanding the psychological factors that
                influence these metrics. That’s where an organisational
                psychology approach comes in.
              </p>

              <h3>Why Organisational Psychology?</h3>
              <p>
                Organisational psychology, the scientific study of human
                behavior in the workplace, examines how individual, group, and
                organizational factors impact employee attitudes, motivations,
                and performance. By incorporating organisational psychology,
                people analytics becomes more than just metrics; it transforms
                into a deeper understanding of why employees behave the way they
                do, how to engage them effectively, and how to build an
                environment that supports well-being and productivity. When
                people analytics is viewed through this lens, we can achieve a
                balance between data-driven decisions and human-centric
                insights, enabling organizations to foster cultures that truly
                support their people.
              </p>

              <h3>
                Key Areas Where Organisational Psychology and People Analytics
                Intersect
              </h3>

              <ol>
                <li>
                  <strong>Employee Engagement and Retention</strong>
                  <p>
                    Retention is one of the top issues people analytics can help
                    address, but metrics like turnover rate and engagement
                    scores only scratch the surface. From an organisational
                    psychology standpoint, we know that retention is influenced
                    by many factors: job satisfaction, alignment with personal
                    values, sense of purpose, and psychological safety, among
                    others. By layering psychological insights with quantitative
                    data, leaders can identify underlying drivers of engagement
                    and develop initiatives that tap into what truly matters to
                    employees. This approach goes beyond generic engagement
                    surveys to a more tailored, data-informed strategy.
                  </p>
                </li>
                <li>
                  <strong>Diversity, Equity, and Inclusion (DEI)</strong>
                  <p>
                    Diversity metrics are often used to track demographic
                    representation, but an organisational psychology approach
                    delves into deeper, more qualitative aspects of DEI. We must
                    consider not only who is in the room, but how inclusive the
                    environment is and whether all voices are being heard.
                    Psychometric assessments and sentiment analysis can identify
                    biases in recruitment, performance reviews, and promotions,
                    helping leaders develop interventions that promote a more
                    inclusive and equitable culture. By integrating quantitative
                    DEI data with an understanding of group dynamics and social
                    identity theory, organizations can create genuinely
                    inclusive environments where all employees feel valued.
                  </p>
                </li>
                <li>
                  <strong>Predictive and Strategic Workforce Planning</strong>
                  <p>
                    Effective workforce planning requires anticipating future
                    needs based on historical data. With insights from
                    organisational psychology, people analytics can forecast
                    more accurately by factoring in employee life-cycle stages,
                    developmental needs, and transition periods. For example,
                    understanding the psychological demands placed on new
                    leaders during critical career transitions can inform
                    training and support systems. Incorporating these insights
                    into workforce planning creates a dynamic and adaptive
                    talent strategy that supports growth at every level.
                  </p>
                </li>
                <li>
                  <strong>Organisational Network Analysis (ONA)</strong>
                  <p>
                    ONA provides a map of relationships and communication
                    patterns within an organization, shedding light on how
                    information flows and where bottlenecks or silos might
                    exist. This analysis gains even more depth when combined
                    with psychological insights into group behavior, social
                    influence, and leadership impact. For example, understanding
                    the psychological impact of certain communication patterns
                    can help leaders promote more open, collaborative
                    environments. Recognizing informal leaders or central nodes
                    in the network can inform succession planning and change
                    management strategies.
                  </p>
                </li>
                <li>
                  <strong>Employee Wellbeing and Psychosocial Hazards</strong>
                  <p>
                    With mental health awareness growing, people analytics can
                    be invaluable in supporting employee well-being. By
                    leveraging well-being surveys and psychological assessments,
                    organizations can identify trends in employee stress,
                    burnout, and overall satisfaction. An organisational
                    psychology approach to these data points emphasizes not only
                    intervention but also prevention, with a focus on creating
                    environments that inherently support mental health. Insights
                    into team dynamics, leadership behaviors, and workload
                    management can guide the development of initiatives that
                    reduce stressors and promote psychological safety, fostering
                    a resilient workforce.
                  </p>
                </li>
              </ol>
              <img
                src="People_analytics.png"
                style={{
                  width: "100%",
                  borderRadius: "10px",
                  marginTop: "4vh",
                  marginBottom: "4vh",
                }}
              ></img>
              <h3>Bridging Data and Human Insight</h3>
              <p>
                A primary challenge in people analytics is balancing objectivity
                with empathy. Data can reveal patterns, but only a nuanced,
                human-centric approach can interpret these patterns in ways that
                lead to positive organizational change. For example, in cases of
                low engagement, quantitative data might point to productivity
                dips or increased absenteeism, but understanding <em>why</em>{" "}
                employees feel disengaged requires a deeper, psychological
                inquiry. By using psychometric tools, surveys, and qualitative
                interviews, organisational psychologists can explore the
                underlying causes—whether it's a misalignment with
                organisational values, a lack of career growth, or unmet social
                support.
              </p>

              <h3>Future Directions for People Analytics</h3>
              <p>
                The integration of organisational psychology and people
                analytics is still in its early stages, but the potential is
                vast. Future applications could include:
              </p>
              <ul>
                <li>
                  <strong>Real-time sentiment analysis</strong> to monitor and
                  respond to changes in employee mood, allowing for more timely
                  interventions.
                </li>
                <li>
                  <strong>Machine learning algorithms</strong> that not only
                  predict turnover but also identify patterns linked to job
                  satisfaction, performance, and well-being.
                </li>
                <li>
                  <strong>Personalised development plans</strong> based on
                  psychological assessments and career trajectory analysis,
                  enabling more effective career support and retention
                  strategies.
                </li>
              </ul>

              <p>
                As people analytics tools become more sophisticated, the role of
                organisational psychology will be even more essential in
                interpreting these complex datasets with sensitivity and
                accuracy.
              </p>

              <h3>Final Thoughts</h3>
              <p>
                The true power of people analytics lies in its ability to reveal
                insights that may otherwise go unnoticed, but data alone is not
                enough. By applying organisational psychology, companies can
                translate data into strategies that genuinely support and
                develop their people. When we understand the human motivations
                and behaviours behind the numbers, people analytics becomes a
                transformative force—one that can help build organisations that
                are not only efficient and productive but also supportive and
                inspiring for all employees.
              </p>
              <p>
                Organisations that embrace this balanced, psychology-informed
                approach to people analytics are better positioned to foster
                resilient, engaged, and motivated workforces, driving success in
                today’s complex, rapidly changing business landscape.
              </p>
            </div>
          </Box>
          <Divider></Divider>
          <Footer />
        </Box>
      </ThemeProvider>
    </>
  );
};

export default People_analytics;
