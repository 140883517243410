import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import MarketingPage from "./HomePage";
import DataPage from "./components/Data_projects.jsx";
import ComingSoon from "./components/ComingSoon.jsx";
import BlogPage from "./components/Blog.jsx";
import People_analytics from "./components/BlogPosts/PeopleAnalystics.jsx";
import High_performing_teams from "./components/BlogPosts/HighPerformingTeams.jsx";
import HTMLPage from "./components/DataProjects/HTML_Page.jsx";
// import Test from "./components/BlogCard.jsx";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MarketingPage />} />
        <Route path="/data" element={<DataPage />} />
        <Route path="/coming-soon" element={<ComingSoon />} />
        <Route path="/blog" element={<BlogPage />} />
        <Route path="/people-analytics" element={<People_analytics />} />
        <Route
          path="/high-performing-teams"
          element={<High_performing_teams />}
        />
        <Route path="/employee-attrition" element={<HTMLPage />} />
      </Routes>
    </Router>
  );
}

export default App;
