import * as React from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import AppAppBar from "./components/AppAppBar";
import Hero from "./components/Hero";
import LogoCollection from "./components/LogoCollection";
import Highlights from "./components/Highlights";
import Pricing from "./components/Pricing";
import Features from "./components/Features";
import Testimonials from "./components/Testimonials";
import FAQ from "./components/FAQ";
import Footer from "./components/Footer";
import getMPTheme from "./theme/getMPTheme";
import TemplateFrame from "./TemplateFrame";

export default function MarketingPage() {
  const [mode, setMode] = React.useState("light");
  const [showCustomTheme, setShowCustomTheme] = React.useState(true);
  const MPTheme = createTheme(getMPTheme(mode));
  const defaultTheme = createTheme({ palette: { mode } });

  // This code only runs on the client side, to determine the system color preference
  React.useEffect(() => {
    // Check if there is a preferred mode in localStorage
    const savedMode = localStorage.getItem("themeMode");
    if (savedMode) {
      setMode(savedMode);
    } else {
      // If no preference is found, it uses system preference
      const systemPrefersDark = window.matchMedia(
        "(prefers-color-scheme: dark)"
      ).matches;
      setMode(systemPrefersDark ? "dark" : "light");
    }
  }, []);

  const toggleColorMode = () => {
    const newMode = mode === "dark" ? "light" : "dark";
    setMode(newMode);
    localStorage.setItem("themeMode", newMode); // Save the selected mode to localStorage
  };

  const toggleCustomTheme = () => {
    setShowCustomTheme((prev) => !prev);
  };

  return (
    <ThemeProvider theme={showCustomTheme ? MPTheme : defaultTheme}>
      <div
        style={{
          backgroundImage:
            "radial-gradient(ellipse 80% 59% at 50% -15%, hsl(120, 100%, 90%), transparent)",
          ...MPTheme.applyStyles("dark", {
            backgroundImage:
              "radial-gradient(ellipse 80% 59% at 50% -15%, hsl(120, 100%, 14%), transparent)",
          }),
        }}
      >
        <TemplateFrame
          toggleCustomTheme={toggleCustomTheme}
          showCustomTheme={showCustomTheme}
          mode={mode}
          toggleColorMode={toggleColorMode}
        >
          <CssBaseline enableColorScheme />
          <AppAppBar />
          <Hero />
          <div>
            <LogoCollection />
            {/* <Features /> */}
            {/* <Divider /> */}
            <Testimonials />
            {/* <Divider /> */}
            {/* <Highlights />
          <Divider /> */}
            {/* <Pricing /> */}
            {/* <Divider /> */}
            {/* <FAQ /> */}
            {/* <Divider /> */}
            <Divider />
            <Footer />
          </div>
        </TemplateFrame>
      </div>
    </ThemeProvider>
  );
}
