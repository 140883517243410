import React from "react";
import { useState } from "react";
import AppAppBar from "./AppAppBar";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import getMPTheme from "../theme/getMPTheme";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";

const ComingSoon = () => {
  const [mode, setMode] = useState("light");
  const [showCustomTheme, setShowCustomTheme] = useState(true);
  const MPTheme = createTheme(getMPTheme(mode));
  const defaultTheme = createTheme({ palette: { mode } });

  const pageStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage:
      "radial-gradient(ellipse 80% 59% at 50% -15%, hsl(120, 100%, 90%), transparent)",
  };

  return (
    <ThemeProvider theme={showCustomTheme ? MPTheme : defaultTheme}>
      <div style={pageStyle}>
        <CssBaseline />
        <Container style={{ display: "flex", marginBottom: "10%" }}>
          <AppAppBar />
        </Container>
        <div style={styles.container}>
          <h1 style={styles.text}>
            Coming soon
            <span style={styles.dots} className="dots"></span>
          </h1>
          <style>{`
        @keyframes dot {
          0%, 20% {
            content: '.';
          }
          40% {
            content: '..';
          }
          60% {
            content: '...';
          }
          80%, 100% {
            content: '';
          }
        }

        .dots::after {
          content: '.';
          animation: dot 1s steps(1, end) infinite;
        }
      `}</style>
        </div>
      </div>
    </ThemeProvider>
  );
};

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "75vh",
  },
  text: {
    fontSize: "48px",
    fontFamily: "Arial, sans-serif",
    color: "#333",
  },
  dots: {
    display: "inline-block",
  },
};

export default ComingSoon;
