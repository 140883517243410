import React from "react";
import { useState, useEffect } from "react";
import { motion } from "framer-motion";

const Test = ({ image, title, description, orderIndex, link }) => {
  function redirectToURL(url) {
    window.location.href = url;
  }

  console.log("Test component rendered");

  function truncateTextAtSpace(text, maxLength) {
    if (text.length <= maxLength) return text;
    const truncated = text.slice(0, maxLength);
    return truncated.slice(0, truncated.lastIndexOf(" ")) + "...";
  }

  return (
    <>
      <style>
        {`

          .blog-card {
            padding: 0px;
          }

          .blog-card h2 {
            margin-top: 14px;
            margin-bottom: 14px;
          }

          .blog-card p {
            margin-top: 14px;
            margin-bottom: 14px;
          }

          .image-container {
            position: relative;
            width: 100%;
            padding-top: 70%;
            overflow: hidden;
            outline: 3px solid rgba(0, 0, 0, 0.2);
            outline-offset: -2px;
            border-radius: 15px;
          }

          .image-container img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 15px;
          }

          .image-container-pp {
            position: relative;
            display: grid;
            grid-template-columns: auto auto;
            align-content: center;
            align-items: center;
            justify-content: flex-end;
          }

          .image-container-pp img {
            width: 3vmin;
            height: 3vmin;
            object-fit: cover;
            border-radius: 50%;
            position: relative;
            right: 0;
          }

          .image-container-pp p {
            margin-right: 10px;
          }

          .image-container:hover {
            outline: 5px solid rgba(0, 0, 0, 0.55);
            outline-offset: -2px;
            border-radius: 15px;
          }

          .text-content {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            padding: 10px;
            padding-bottom: 0px;
            padding-top: 0px;
            color: white;
            text-align: right;
            box-sizing: border-box;
          }

          .gradient-overlay {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 70%;
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 50%, rgba(0, 0, 0, 0.9) 100%);
            border-radius: 15px;
          }
        `}
      </style>

      <motion.div
        key="employee-attrition"
        className="blog-card"
        initial={{ opacity: 0, x: -70 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{
          duration: 1,
          delay: parseFloat(orderIndex) * 0.3,
          ease: "easeOut",
        }}
        viewport={{ once: true }}
        onClick={() => redirectToURL(link)}
      >
        <div className="image-container">
          <img src={image} alt="employee-attrition" />
          <div className="gradient-overlay"></div>
          <div className="text-content">
            <h2 style={{ padding: 0, fontSize: "150%" }}>{title}</h2>
            <div className="image-container-pp" style={{ height: "10px" }}>
              <p
                style={{
                  fontSize: "120%",
                  fontWeight: "bold",
                }}
              >
                Luke Carroll
              </p>
              <img src="headshot.jpg" alt="profile" />
            </div>
            <p style={{ fontSize: "110%" }}>
              {truncateTextAtSpace(description, 90)}
            </p>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default Test;
