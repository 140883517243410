import * as React from "react";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { useTheme } from "@mui/system";
import { motion } from "framer-motion"; // Import motion

const userTestimonials = [
  {
    avatar: <Avatar alt="Opposite Consulting" src="" />,
    name: "Opposite Consulting",
    occupation: "Human-Centred Design Specialist",
    testimonial:
      "Applied a comprehensive personality model to develop an ESG assessment for a major Australian property developer.\nDesigned engaging, evidence-based workshops for diverse clients, including international construction and logistics firms, rail services, universities, and property developers.\nCreated a natural language tool to analyse survey responses, improving the efficiency and quality of client deliverables.\nDeveloped a Linear Regression forecasting tool in Excel to identify inefficiencies in project management and client relations. \nDesigned and implemented an R script to analyse large-scale survey data, enhancing data-driven decision-making.",
  },
  {
    avatar: <Avatar alt="The Royal Children's Hospital" src="" />,
    name: "The Royal Children's Hospital",
    occupation: "Organisational Development",
    testimonial:
      "Developed four micro-skill workshops on listening and managing high-pressure situations for a hospital-wide cultural development program.\nContributed to the analysis plan and led quality assurance reviews for a 7-year cultural development program with key stakeholders.\nCreated custom Excel VBA functions during my placement at the Royal Children’s Hospital, saving 40 hours of manual data processing and enabling the team to focus on delivering insights.",
  },
  {
    avatar: <Avatar alt="ANZ Bank" src="" />,
    name: "ANZ Bank",
    occupation: "Leadership & Talent",
    testimonial:
      "Conducted 15 qualitative interviews to assess transition periods and developmental needs for new leaders, leading to recommendations for enterprise-wide leadership development modules.\nDeveloped a recursive function and user-friendly UI for categorising leader types, enhancing leadership analytics and reducing cross-functional dependencies.\nImplemented data workflows in Power BI to create dashboards, providing insights into leadership development and business outcomes across divisions.",
  },
  {
    avatar: <Avatar alt="Monash University" src="" />,
    name: "Monash University",
    occupation: "Teaching Associate",
    testimonial:
      "Facilitate an active learning environment for two classes of 35+ university age students in PSY2061—The Biopsychological model, fostering inclusivity by enabling students who find complex anatomical and psychological concepts challenging to actively participate and engage.\nExample marking load includes grading 70 third-year Neuropsychology assignments, each 2,500 words, within a 20- day turnaround, with all work moderated and approved by the course professor.",
  },
  {
    avatar: <Avatar alt="Dentaview" src="" />,
    name: "Dentaview",
    occupation: "Medical Administrator",
    testimonial:
      "General responsibilities include managing patient logistics, managing relations with referring specialists and clinic logistics. Self-initiated tasks included producing automated weekly data reports for the business owner and project managing the recruitment of local medical specialists, resulting in a 36% increase in patient referrals.\nSuccessfully identified new vendors for clinic inventory that led to a significant cost reduction of 58%. This initiative ensured a reliable and cost-effective supply chain for essential clinic resources.\nImplemented an automated invoicing system, reducing client payment delays, improving cash management and streamlining the preparation and follow-up of invoices.",
  },
];

const renderTestimonialList = (testimonial) => {
  // Split the testimonial by newlines and trim any excess spaces
  const testimonialItems = testimonial.split("\n").map((item) => item.trim());

  // Return an unordered list of testimonial items
  return (
    <ul>
      {testimonialItems.map((item, index) => (
        <li key={index}>{item}</li>
      ))}
    </ul>
  );
};

const whiteLogos = [
  "logo 2024 Final v2-06.svg",
  "RCH.png",
  "ANZ-Logo-2009.svg",
  "Monash.svg",
  "dentaview.png",
];

const logoStyle = {
  width: "100px",
  height: "100px",
  objectFit: "contain",
  opacity: 1,
};

export default function Testimonials() {
  const theme = useTheme();
  const logos = whiteLogos;

  return (
    <>
      <Container
        id="testimonials"
        sx={{
          pb: { xs: 8, sm: 16 },
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: { xs: 3, sm: 6 },
        }}
      >
        <Box
          sx={{
            width: { sm: "100%", md: "60%" },
            textAlign: { sm: "left", md: "center" },
          }}
        >
          <Typography
            component="h1"
            variant="h3"
            gutterBottom
            sx={{ color: "text.primary", pb: 2 }}
          >
            Work Experience
          </Typography>
          <Typography variant="body1" sx={{ color: "text.secondary" }}>
            I’m passionate about making a positive impact in organisational
            psychology. With a focus on data-driven decision-making, innovative
            solutions, and leadership development, I want to be valuable part of
            achieving meaningful results. I enjoy sharing insights and ideas
            that can lead to measurable outcomes.
          </Typography>
        </Box>
        <Grid container spacing={2}>
          {userTestimonials.map((testimonial, index) => (
            <Grid
              item
              xs={12}
              sm={12}
              key={index}
              sx={{ display: "flex", alignItems: "stretch" }}
            >
              <motion.div
                initial={{ opacity: 0, x: -70 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{
                  duration: 1,
                  delay: 0.3,
                  ease: "easeOut",
                }}
                viewport={{ once: true }}
                style={{ flexGrow: 1 }}
              >
                <Card
                  variant="outlined"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    flexGrow: 1,
                    width: "100%",
                    gap: 0,
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <CardHeader
                      avatar={testimonial.avatar}
                      title={
                        <Typography variant="subtitle2" fontWeight="bold">
                          {testimonial.name}
                        </Typography>
                      }
                      subheader={testimonial.occupation}
                    />
                    <img
                      src={logos[index]}
                      alt={`Logo ${index + 1}`}
                      style={logoStyle}
                    />
                  </Box>
                  <CardContent>
                    <Typography
                      variant="body1"
                      gutterBottom
                      sx={{
                        color: "text.secondary",
                        whiteSpace: "pre-wrap",
                      }}
                    >
                      {renderTestimonialList(testimonial.testimonial)}
                    </Typography>
                  </CardContent>
                </Card>
              </motion.div>
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
}
