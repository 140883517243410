import * as React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";

const StyledBox = styled("div")(({ theme }) => ({
  alignSelf: "center",
  width: "150px",
  height: "150px",
  marginTop: "30px",
  borderRadius: theme.shape.borderRadius,
  outline: "6px solid",
  outlineColor: "hsla(120, 25%, 80%, 0.3)",
  border: "1px solid",
  borderColor: theme.palette.grey[200],
  boxShadow: "0 0 12px 8px hsla(120, 25%, 80%, 0.5)",
  // overflow: "clip",
  backgroundImage: `url(${"headshot.jpg"})`,
  backgroundSize: "cover",
  backgroundPosition: "center",
  [theme.breakpoints.up("sm")]: {
    height: 150,
  },
  ...theme.applyStyles("dark", {
    boxShadow: "0 0 24px 12px hsla(120, 100%, 25%, 0.2)",
    backgroundImage: `url(${"headshot.jpg"})`,
    outlineColor: "hsla(120, 20%, 42%, 0.1)",
    borderColor: theme.palette.grey[700],
  }),
}));

export default function Hero() {
  return (
    <Box
      id="hero"
      sx={(theme) => ({
        width: "100%",
        backgroundRepeat: "no-repeat",
        backgroundOverflow: "visible",
      })}
    >
      <Container
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          pt: { xs: 14, sm: 25 },
          pb: { xs: 8, sm: 12 },
        }}
      >
        <Stack
          spacing={2}
          useFlexGap
          sx={{
            alignItems: "center",
            width: { xs: "100%", sm: "70%" },
          }}
        >
          <Typography
            variant="h1"
            sx={{
              display: "flex",
              flexDirection: { xs: "column", sm: "row" },
              alignItems: "center",
              fontSize: "clamp(3rem, 10vw, 3.5rem)",
            }}
          >
            Welcome&nbsp;to my&nbsp;
            <Typography
              component="span"
              variant="h1"
              sx={(theme) => ({
                fontSize: "inherit",
                color: "#6bd46a",
                ...theme.applyStyles("dark", {
                  color: "primary.light",
                }),
              })}
            >
              Website
            </Typography>
          </Typography>
          <Typography
            sx={{
              textAlign: "center",
              color: "text.secondary",
              width: { sm: "100%", md: "80%" },
            }}
          >
            Here I display my relevant work experience, personal and data
            projects, and test out my web development skills. Please feel free
            to reach out and connect with me on LinkedIn, link is in the menu
            bar.
          </Typography>
          {/* <img
            src="B596C377-C3AB-4B7F-8DC1-CB72656CCED1 Medium.jpeg"
            alt="Profile"
            style={{
              width: "150px",
              height: "150px",
              borderRadius: "10%",
              objectFit: "cover",
            }}
          /> */}
        </Stack>
        <StyledBox id="image">
          {/* {" "}
          <img
            src="headshot.jpg"
            style={{
              height: "150px",
              transform: "translate(-35px, 0px)",
            }}
          ></img> */}
        </StyledBox>
      </Container>
    </Box>
  );
}
