import React from "react";
import { useState } from "react";
import AppAppBar from "../AppAppBar";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import getMPTheme from "../../theme/getMPTheme";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Footer from "../Footer";
import Divider from "@mui/material/Divider";

const High_performing_teams = () => {
  const [mode, setMode] = useState("light");
  const [showCustomTheme, setShowCustomTheme] = useState(true);
  const MPTheme = createTheme(getMPTheme(mode));
  const defaultTheme = createTheme({ palette: { mode } });

  const pageStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  };

  const containerStyle = {
    fontFamily: "Arial, sans-serif",
    color: "#333",
    margin: 0,
    padding: 0,
  };

  const headerStyle = {
    backgroundColor: "#f5f5f5",
    padding: "20px",
    textAlign: "center",
  };

  const sectionStyle = {
    padding: "20px",
    margin: "20px",
    borderBottom: "1px solid #ddd",
  };

  const gridContainerStyle = {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: "20px",
    margin: "20px",
  };

  const gridBoxStyle = {
    backgroundColor: "#f2f2f2",
    padding: "20px",
    borderRadius: "15px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    textAlign: "left",
  };

  const imageStyle = {
    width: "100%",
    height: "auto",
    borderRadius: "8px",
    marginTop: "10px",
  };

  const headingStyle = {
    fontSize: "2.5em",
    color: "#333",
  };

  const subheadingStyle = {
    fontSize: "1.8em",
    marginTop: 0,
  };

  const textStyle = {
    fontSize: "1em",
    lineHeight: "1.6",
  };

  return (
    <>
      <ThemeProvider theme={showCustomTheme ? MPTheme : defaultTheme}>
        <Box style={pageStyle}>
          <CssBaseline /> {/* Normalize styles */}
          <Box
            sx={{
              display: "flex",
              width: "100%",
              backgroundImage: `url(${"teamwork.jpeg"})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <Container
              style={{
                display: "flex",
                marginBottom: "40vh",
              }}
            >
              <AppAppBar />
            </Container>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "59%",
            }}
          >
            <div className="App">
              <header>
                <h1>Developing High Performing Teams</h1>
              </header>
              <section
                style={{
                  backgroundColor: "#f2f2f2",
                  display: "grid",
                  gap: "0px",
                  alignContent: "center",
                  borderRadius: "8px",
                }}
              >
                <h2
                  style={{
                    width: "100%",
                    paddingLeft: "3vh",
                    paddingBottom: "0px",
                  }}
                >
                  Key Takeaways
                </h2>
                <ul>
                  <li>
                    Use task needs analysis to identify KSAs when adjusting team
                    inputs.
                  </li>
                  <li>
                    Employ team development interventions targeting mediating
                    mechanisms when facing structural limitations.
                  </li>
                  <li>
                    Team Building enhances social relations and role clarity.
                  </li>
                  <li>
                    Leadership development improves team effectiveness and
                    outcomes by 29%.
                  </li>
                  <li>
                    Team development interventions targeting mediating
                    mechanisms offer the best ROI for organisations.
                  </li>
                </ul>
              </section>
              <section>
                <h2>Introduction</h2>
                <p>
                  The continual pursuit of refinement and efficiency has led
                  many organisations to heavily integrate teams into day-to-day
                  operations. Instability within the macro economy, increased
                  domestic and international competition are all factors that
                  have led organisations to be agile and protect themselves from
                  the impending complexities of modern-day business. Beyond
                  serving as a safeguard against risks, teams provide
                  organisations with increased efficiencies that translate into
                  performance gains and ultimately increased profitability.
                  Specifically, understanding teams and cultivating
                  high-performing teams can allow organisations to better
                  utilise pre-existing human capital to generate performance
                  gains. This report will examine the factors that influence
                  teams as well as evidence-based best practices that can
                  facilitate high-performing teams within an organisational
                  setting.
                </p>
              </section>

              <section>
                <h2>Defining Teams</h2>
                <p>
                  A team, within an organisational context, is two or more
                  individuals who socially interact (in-person or virtually)
                  with each other. These individuals share one or more common
                  goals and are brought together to perform and complete tasks
                  that align with organisational objectives. The individuals are
                  interconnected in terms of workflow, goals, and outcomes,
                  meaning that each member's contribution affects the overall
                  team outcome. Additionally, each team member has different
                  roles and responsibilities that contribute to achieving the
                  team goal. Finally, the team is a functional element that is
                  part of a broader organisational system, with similarities and
                  differences from the general organisational objectives.
                </p>
              </section>

              <section>
                <h2>Importance of High-Performing Teams</h2>
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(2, 1fr)",
                    gap: "20px",
                  }}
                >
                  <p>
                    High-performing teams can provide a significant competitive
                    advantage for organisations in areas such as productivity,
                    profitability, and customer satisfaction. As the prevalence
                    of team-based organisations rises, the underutilisation of
                    teams, specifically high-performing teams, will result in a
                    drop in and sacrifice of competitive advantage. Industry
                    exploration suggests that organisations with
                    high-performance teams are almost two times more likely to
                    be financially successful than organisations in the same
                    market area.
                  </p>
                  <blockquote
                    style={{
                      backgroundColor: "#f2f2f2",
                      display: "grid",
                      alignContent: "center",
                      textAlign: "center",
                      borderRadius: "8px",
                      padding: 2,
                    }}
                  >
                    <strong>
                      “Coming together is a beginning. Keeping together is
                      progress. Working together is success.”
                    </strong>
                    – Henry Ford
                  </blockquote>
                </div>
              </section>

              <section>
                <h2>Understanding Teams</h2>
                <p>
                  Along with having a prevailing role in organisations, teams
                  are an integral part of wider society and have garnered
                  significant attention in research. From this literature, teams
                  and their subsequent performance are conceptualized as a
                  multi-level process consisting of team-level taskwork,
                  individual-level taskwork, and team member interactions. A
                  leading framework within the team’s literature is the
                  Input-Mediators-Output (IMO) framework. Furthermore, the IMO
                  framework provides a broad foundational understanding of teams
                  that is necessary for later parts of this report that explore
                  how to develop high-performing teams.
                </p>
              </section>

              <section>
                <h3>Inputs</h3>
                <p>
                  In this framework, Inputs refer to predisposed factors that
                  facilitate or limit the efficacy of taskwork. Example inputs
                  include individual team member traits (knowledge, skills,
                  attitudes and personality), task characteristics, team design
                  (composition and size), and organisational context. Overall,
                  inputs refer to relatively tangible aspects of groups and are
                  important elements to factor in when forming a team.
                </p>

                <div style={{ display: "flex", justifyContent: "center" }}>
                  <img
                    src="Picture 1.png"
                    style={{
                      width: "40%",
                      borderRadius: "10px",
                      marginTop: "4vh",
                      marginBottom: "4vh",
                    }}
                  ></img>
                </div>

                <h3>Mediators</h3>
                <p>
                  Mediating mechanisms describe how team outcomes are derived
                  from the set of inputs; in essence, how team members interact
                  to accomplish the task. Marks and colleagues found that whilst
                  behavioural processes were a part of linking inputs with
                  outcomes, the team’s attitudes and cognitions were also an
                  important element. Therefore, mediating mechanisms are often
                  referred to as the ABC’s (Attitudes, Behaviours, and
                  Cognitions).
                </p>
                <section style={gridContainerStyle}>
                  <div style={gridBoxStyle}>
                    <p style={textStyle}>
                      <strong>Attitudes</strong> are an internal state that
                      impacts and dictates the interaction between team members.
                      Attitudes include mutual trust, team cohesion,
                      psychological safety5 and collective efficacy1.
                    </p>
                  </div>

                  <div style={gridBoxStyle}>
                    <p style={textStyle}>
                      <strong>Behaviours</strong> are the processes and skills
                      required to accomplish teamwork. This includes team
                      leadership, information sharing, conflict resolution and
                      backup behaviour2.
                    </p>
                  </div>

                  <div style={gridBoxStyle}>
                    <p style={textStyle}>
                      <strong>Cognitions</strong> are collective experiences and
                      knowledge that provide insight to facilitate effective
                      teamwork. Cognitions include shared mental models3,
                      situational awareness and transactive memory systems4.
                    </p>
                  </div>
                </section>

                <h3>Outcomes</h3>
                <p>
                  Outcomes are largely defined as the accomplishment of
                  individual, team, or organisational goals. In general, teams
                  are utilised to increase organisational efficiency; however,
                  it is also important to understand and measure individual
                  experiences as this will inform their preference towards
                  teamwork moving forward. Goal accomplishment measurement
                  includes:
                </p>
                <ul>
                  <li>
                    Evaluating and quantifying team outputs with a focus on
                    efficiency, innovation, and productivity metrics.
                  </li>
                  <li>
                    Measuring team member attitudes, specifically trust, team
                    efficacy, wellbeing, and satisfaction.
                  </li>
                  <li>
                    Assessing behaviours such as turnover and absenteeism.
                  </li>
                </ul>
              </section>

              <section>
                <h2>Effective Teams</h2>
                <p>
                  Understanding what elements have been associated with
                  effective teams is crucial for developing high-performing
                  teams. Derived from the literature, the following are key
                  scientific findings linking team elements with highly
                  effective teams:
                </p>
                <ul>
                  <li>
                    <strong>Team knowledge, skills, and attitudes</strong>:
                    Required competencies ensure effective teamwork.
                  </li>
                  <section style={gridContainerStyle}>
                    <div style={gridBoxStyle}>
                      <p style={textStyle}>
                        Team <strong>knowledge</strong> is an understanding of
                        the necessary information for effective performance,
                        such as member expertise and clarity of team member
                        roles.
                      </p>
                    </div>

                    <div style={gridBoxStyle}>
                      <p style={textStyle}>
                        Team <strong>skills</strong> are observable capabilities
                        required to accomplish team tasks and teamwork. Examples
                        include communication style and adaptableness.
                      </p>
                    </div>

                    <div style={gridBoxStyle}>
                      <p style={textStyle}>
                        Team <strong>attitudes</strong> are the underlying
                        perceptions and emotions surrounding the teamwork. This
                        includes mutual trust and preference for working within
                        a team.
                      </p>
                    </div>
                  </section>
                  <li>
                    <strong>Team task analysis</strong>: Conducting needs
                    analysis to identify teamwork behaviours and tasks.
                  </li>
                  <li>
                    <strong>Team selection methods</strong>: Selecting team
                    members based on knowledge, skills, and attitudes.
                  </li>
                  <li>
                    <strong>Team leader development</strong>: Developing leader
                    capabilities to enhance team functioning.
                  </li>
                  <li>
                    <strong>Performance measurement system</strong>: Assessing
                    individual and team processes to evaluate performance.
                  </li>
                  <li>
                    <strong>Diagnostic feedback</strong>: Providing feedback to
                    adjust team behaviours for future improvement.
                  </li>
                </ul>
              </section>

              <section>
                <h2>Best Practices</h2>
                <p>
                  Teams are complex multi-level entities, thus, when developing
                  high-performing teams, it is paramount to identify the most
                  pertinent approach based on the context. When forming
                  high-performance teams, it is possible to influence at the
                  input levels, such as team composition and member traits.
                  Alternatively, in order to develop high performance within
                  pre-established teams, targeting mediating mechanisms can be
                  more effective.
                </p>
              </section>

              <section>
                <h2>
                  Developing High Performance: Mediating Mechanism Focused
                </h2>
                <p>
                  Team development interventions (TDIs) aim to improve team
                  performance by equipping teams with KSA competencies. This
                  section assesses two common forms of TDIs: team building and
                  leadership training.
                </p>

                <h3>Team Building</h3>
                <p>
                  Team building is a series of interventions aimed at improving
                  social interactions and interpersonal relations. These
                  interventions include:
                </p>
                <ul>
                  <li>Goal setting</li>
                  <li>Interpersonal relationship management</li>
                  <li>Role clarification</li>
                  <li>Problem-solving</li>
                </ul>

                <h3>Leadership Development</h3>
                <p>
                  Leadership training focuses on developing capabilities such as
                  conflict resolution, feedback provision, and psychological
                  safety. It includes online modules and in-person sessions to
                  help team leaders apply KSAs in a real-world context.
                </p>
              </section>
            </div>
          </Box>
          <Divider></Divider>
          <Footer />
        </Box>
      </ThemeProvider>
    </>
  );
};

export default High_performing_teams;
