import React from "react";
import { useState } from "react";
import AppAppBar from "./AppAppBar";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import getMPTheme from "../theme/getMPTheme";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Footer from "./Footer";
import Test from "./BlogCard.jsx";
import Divider from "@mui/material/Divider";
import { Opacity } from "@mui/icons-material";

const DataPage = () => {
  const [mode, setMode] = useState("light");
  const [showCustomTheme, setShowCustomTheme] = useState(true);
  const MPTheme = createTheme(getMPTheme(mode));
  const defaultTheme = createTheme({ palette: { mode } });

  const pageStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage:
      "radial-gradient(ellipse 80% 59% at 50% -15%, hsl(120, 100%, 90%), transparent)",
  };

  return (
    <>
      <ThemeProvider theme={showCustomTheme ? MPTheme : defaultTheme}>
        <CssBaseline /> {/* Normalize styles */}
        <Box style={pageStyle}>
          <Container style={{ display: "flex", marginBottom: "20vh" }}>
            <AppAppBar />
          </Container>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: { sm: "90%", lg: "75%" },
            }}
          >
            <div style={{ width: "100%" }}>
              <h1>Data Projects</h1>
              <h3>Recent</h3>
            </div>
          </Box>
        </Box>
        <Box
          sx={{
            display: "grid",
            gap: "50px",
            maxWidth: "75%",
            margin: "0 auto",
            marginTop: "20px",
            gridTemplateColumns: {
              md: "repeat(2, 1fr)",
              lg: "repeat(2, 1fr)",
            },
          }}
        >
          <Test
            image="emp-attrition.jpeg"
            title="Machine Learning Model- Employee Attrition "
            description="Using a Random Forrest Classification, this model is trained to use predictor variables to estimate the attrition of employees."
            orderIndex="1"
            link="/employee-attrition"
          ></Test>
          {/* <Test
            image="emp-remuneration.jpeg"
            title="Machine Learning Model- Employee Remuneration"
            description="Developing a neural network model to accurately predict employee remuneration based on various personal, performance, and market factors."
            orderIndex="2"
            link=""
          ></Test>
          <Test
            image="product-pricing.jpeg"
            title="Machine Learning Model- Product Pricing"
            description="Creating a nmachine learning model to identify most efficient pricing for full list of inventory."
            orderIndex="3"
            link=""
          ></Test> */}
        </Box>
        <Box sx={{ marginTop: "10vh" }}>
          <Divider></Divider>
          <Footer />
        </Box>
      </ThemeProvider>
    </>
  );
};

export default DataPage;
