import React from "react";
import { useState } from "react";
import AppAppBar from "../AppAppBar";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import getMPTheme from "../../theme/getMPTheme";
import CssBaseline from "@mui/material/CssBaseline";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Footer from "../Footer";
import Divider from "@mui/material/Divider";

const HTMLPage = () => {
  const [mode, setMode] = useState("light");
  const [showCustomTheme, setShowCustomTheme] = useState(true);
  const MPTheme = createTheme(getMPTheme(mode));
  const defaultTheme = createTheme({ palette: { mode } });

  const pageStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    backgroundImage:
      "radial-gradient(ellipse 80% 59% at 50% -15%, hsl(120, 100%, 90%), transparent)",
  };

  return (
    <>
      <ThemeProvider theme={showCustomTheme ? MPTheme : defaultTheme}>
        <Box style={pageStyle}>
          <CssBaseline /> {/* Normalize styles */}
          <Container style={{ display: "flex", marginBottom: "20vh" }}>
            <AppAppBar />
          </Container>
          <Box
            sx={{
              width: "95%",
              borderRadius: "15px", // Set your preferred border radius
              overflow: "hidden", // Ensures the iframe stays inside the rounded container
              justifyContent: "center",
            }}
          >
            <h1
              style={{
                display: "flex",
                justifyContent: "center",
                margin: "2vh",
              }}
            >
              Employee Attrition
            </h1>
            <Box sx={{ height: "80vh" }}>
              <iframe
                src="/EmployeeAttrition.html"
                style={{
                  width: "100%", // Make the iframe take the full width of its container
                  height: "100%", // Set the height of the iframe
                  border: "none", // Remove iframe borders if needed
                }}
                title="Employee Attrition"
              />
            </Box>
          </Box>
          <Divider></Divider>
          <Footer />
        </Box>
      </ThemeProvider>
    </>
  );
};

export default HTMLPage;
